import TopBar from "../../../components/TopBar";
import Navbar from "../../../components/navbar";

import "../../../assets/css/docu.css";

import { useMutation } from "@apollo/client";
import {
  LINKS_ETABLISSMENT_MUTATION,
  USER_ID_LS_KEY,
} from "../../../queries/uniteTravail";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useRef, useState } from "react";
import LoadingComponent from "../../../components/Loading";
import ErrorComponent from "../../../components/ErrorComponent";
import { UPDATE_DOCUMENT_UNIQUE } from "../../../queries/documentUnique";
import API_URL from "../../../utils/api";

const Risques = () => {
  const oldModel = useRef([] as any);
  const model = useRef([] as any);
  const [loading, setLoading] = useState(true);
  const [pageToSwitch, setPageToSwitch] = useState("");
  const mutationQueue = useRef(undefined as any);

  useEffect(() => {
    fetch(
      `${API_URL}/rest/user/model/${localStorage.getItem(
        USER_ID_LS_KEY
      )}/${localStorage.getItem("idEntreprise")}`
    ).then((res) => {
      res.json().then((data) => {
        data = data.filter((ut: any) => ut.checked);
        model.current = data;
        oldModel.current = data;
        setLoading(false);
      });
    });
  }, []);

  const [mutateDocUnique, docUniqueMutation] = useMutation(
    UPDATE_DOCUMENT_UNIQUE
  );
  const [linksMutation, mutation] = useMutation(LINKS_ETABLISSMENT_MUTATION);

  const mutate = (data: any) => {
    if (mutation.loading) {
      mutationQueue.current = {
        linksAdd: [
          ...(mutationQueue.current?.linksAdd.filter(
            (item: any) =>
              !data.linksDelete.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              ) &&
              !data.linksAdd.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              )
          ) ?? []),
          ...data.linksAdd,
        ],
        linksDelete: [
          ...(mutationQueue.current?.linksDelete.filter(
            (item: any) =>
              !data.linksAdd.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              ) &&
              !data.linksDelete.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              )
          ) ?? []),
          ...data.linksDelete,
        ],
      };
    } else {
      linksMutation({
        variables: {
          id: localStorage.getItem("idEntreprise"),
          linksAdd: data.linksAdd,
          linksDelete: data.linksDelete,
        },
      });
    }
  };

  useEffect(() => {
    if (!mutation.loading && mutationQueue.current != undefined) {
      let toMutate = mutationQueue.current;
      mutationQueue.current = undefined;
      linksMutation({
        variables: {
          id: localStorage.getItem("idEntreprise"),
          linksAdd: toMutate.linksAdd,
          linksDelete: toMutate.linksDelete,
        },
      }).then(async () => {
        if (pageToSwitch != "" && mutationQueue.current == undefined) {
          window.location.href = pageToSwitch;
        }
      });
    } else {
      if (pageToSwitch != "") {
        window.location.href = pageToSwitch;
      }
    }
  }, [mutation]);

  if (loading) return <LoadingComponent />;

  if (mutation.loading && pageToSwitch != "") return <LoadingComponent />;
  if (mutation.error) return <ErrorComponent  error={mutation.error}/>;

  if (docUniqueMutation.loading) return <LoadingComponent />;
  if (docUniqueMutation.error) return <ErrorComponent error={docUniqueMutation.error} />;

  return (
    <>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Sélection des risques</h1>
          <div className="pageLine"></div>
          <div className="affichages">
            <p>
              Voici les risques les plus fréquemment identifiés pour votre
              activité.
            </p>
            <p>
              Cochez la case si le risque est identifié dans votre entreprise.
            </p>
            <p>
              Si vous estimez qu'il manque un risque sur une unité, n'hésitez
              pas, posez-nous la question.
            </p>
            <button
              onClick={async () => {
                let toMutate = model.current.reduce((acc: [], ut: any) => {
                  return [
                    ...acc,
                    ...ut.risques.map((risque: any) => {
                      risque.checked = true;
                      return {
                        uniteTravailID: ut.id,
                        risque: { id: risque.id, haveDesc: false },
                      };
                    }),
                  ];
                }, []);
                for (let i = 0; i < toMutate.length; i += 25) {
                  mutate({
                    linksAdd: toMutate.slice(i, i + 25),
                    linksDelete: [],
                  });
                  // wait 2 sec
                  await new Promise((resolve) => setTimeout(resolve, 2000));
                }
                console.log("end");
              }}
            >
              Selectionner tout
            </button>
            {model.current.map((ut: any, index: number) => (
              <Accordion className="accordion" key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="textPrimary">
                        {ut.libelle}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TableContainer
                        component={Paper}
                        className="table_risques"
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Risques identifiés</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ut.risques.map(
                              (risque: any, indexRisque: number) => (
                                <TableRow key={indexRisque}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ width: "20%" }}
                                  >
                                    <Checkbox
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "secondary checkbox",
                                      }}
                                      checked={risque.checked || false}
                                      onChange={(event) => {
                                        risque.checked = event.target.checked;
                                        mutate({
                                          linksAdd: risque.checked
                                            ? [
                                                {
                                                  uniteTravailID: ut.id,
                                                  risque: {
                                                    id: risque.id,
                                                    haveDesc: false,
                                                  },
                                                },
                                              ]
                                            : [],
                                          linksDelete: !risque.checked
                                            ? [
                                                {
                                                  uniteTravailID: ut.id,
                                                  risque: {
                                                    id: risque.id,
                                                  },
                                                },
                                                ...risque.descriptions
                                                  .filter(
                                                    (desc: any) =>
                                                      desc.id !== risque.id &&
                                                      desc.checked
                                                  )
                                                  .map((desc: any) => ({
                                                    uniteTravailID: ut.id,
                                                    risque: {
                                                      id: desc.id,
                                                    },
                                                  })),
                                              ]
                                            : [],
                                        });
                                      }}
                                    />
                                    {risque.libelle}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="button-style-doc"
                onClick={async () => {
                  await mutateDocUnique({
                    variables: {
                      etablissementID: localStorage.getItem("idEntreprise"),
                      isvalid: false,
                      avancement: 1,
                    },
                  });
                  window.location.href = "/unites-de-travail";
                }}
              >
                Précédent
              </button>
              <button
                className="button-style-doc"
                onClick={async () => {
                  await mutateDocUnique({
                    variables: {
                      etablissementID: localStorage.getItem("idEntreprise"),
                      isvalid: false,
                      avancement: 3,
                    },
                  });
                  window.location.href = "/description-des-risques";
                }}
              >
                Suivant
              </button>
              {mutation.loading && "sauvegarde en cours..."}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Risques;
