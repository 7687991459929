import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import { useQuery } from "@apollo/client";
import {
  GET_DOCUMENT_UNIQUE_INFOS,
  GET_FULL_DOCUMENT_UNIQUE,
  UPDATE_DOCUMENT_UNIQUE,
} from "../../queries/documentUnique";
import { USER_ID_LS_KEY } from "../../queries/uniteTravail";
import { useState } from "react";

import client from "../../utils/http-common";

import "../../assets/css/affichageDU.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import LoadingComponent from "../../components/Loading";
import { reformatDatas } from "../../utils/formatDatas";
import ErrorComponent from "../../components/ErrorComponent";
import RisqueCalcul from "../../utils/RisqueCalcul";

type Mesure = {
  ponderation: number;
  id: string;
};

type Description = {
  mesures: Mesure[];
  gravite: number;
  frequence: number;
  id: string;
};





const DocumentUniqueConsultation = () => {
  const [errorState, setErrorState] = useState<any>(null);
  const { data, loading, error } = useQuery(GET_FULL_DOCUMENT_UNIQUE, {
    variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
    onCompleted(data) {
      setFormattedData(reformatDatas(data));
    },
    fetchPolicy: "no-cache",
  });

  const documentUniqueInfos = useQuery(GET_DOCUMENT_UNIQUE_INFOS, {
    variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
  });

  const [formattedData, setFormattedData] = useState<any[]>([]);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;
  if (documentUniqueInfos.loading) return <LoadingComponent />;

  const openNewWindow = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    window.open(
      "/imprimer-mon-document-unique",
      "_blank",
      `width=${width},height=${height}`
    );
  };

  return (
    <>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Mon Document Unique</h1>
          <div className="pageLine"></div>
          <div className="affichages">
            {!documentUniqueInfos ? (
              <p>
                Vous n'avez pas de document unique, si vous pensez que c'est une
                erreur veuillez contacter un administrateur
              </p>
            ) : (
              <>
                <div className="ButtonsBox">
                  <button
                    // button to return to the previous page
                    className="DUbuttons"
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    Retour
                  </button>
                  <button onClick={openNewWindow} className="DUbuttons">
                    Imprimer
                  </button>
                  <button
                    className="hover_button boutonmiseajour"
                    onClick={() => {
                      console.log(
                        "clicked",
                        localStorage.getItem("idEntreprise")
                      );
                      client
                        .mutate({
                          mutation: UPDATE_DOCUMENT_UNIQUE,
                          variables: {
                            etablissementID:
                              localStorage.getItem("idEntreprise"),
                            dateedition: new Date().toISOString(),
                            isvalid: true,
                          },
                        })
                        .then((data) => {
                          window.alert("Document Unique mis à jour !");
                        });
                      // // TODO(): isvalid mutation
                    }}
                  >
                    Valider mon document
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: "30px",
                    marginTop: "20px",
                    overflow: "hidden",
                  }}
                >
                  <div id="tableau">
                    <TableContainer style={{ overflow: "hidden" }}>
                      <Table>
                        <TableBody
                          style={{
                            margin: 0,
                            padding: 0,
                            display: "inline-block",
                          }}
                        >
                          <TableRow
                            style={{
                              margin: 0,
                              padding: 0,
                              display: "inline-block",
                            }}
                          >
                            <TableCell className="celluleTitre unitehead">
                              UNITES TRAVAIL
                            </TableCell>
                            <TableCell className="celluleTitre risquehead">
                              RISQUES
                            </TableCell>
                            <TableCell className="celluleTitre descriptionhead">
                              SITUATIONS
                            </TableCell>
                            <TableCell className="celluleTitre frequencehead">
                              FREQ
                            </TableCell>
                            <TableCell className="celluleTitre frequencehead">
                              GRAV
                            </TableCell>
                            <TableCell className="celluleTitre mesurehead">
                              PREVENTION
                            </TableCell>
                            <TableCell className="celluleTitre specificitehead">
                              SPECIFICITES
                            </TableCell>
                            <TableCell className="celluleTitre frequencehead">
                              POND
                            </TableCell>
                            <TableCell className="celluleTitre frequencehead">
                              MAIT
                            </TableCell>
                            <TableCell className="celluleTitre frequencehead">
                              CRIT
                            </TableCell>
                            <TableCell
                              className="celluleTitre frequencehead"
                              style={{ paddingRight: "8px" }}
                            >
                              PRIO
                            </TableCell>
                          </TableRow>
                        </TableBody>
                        <TableBody>
                          {formattedData.map((ut: any) => (
                            <TableRow
                              style={{
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <div className="uniteencadrement">
                                <TableCell className="unitecontent">
                                  {ut.libelle}
                                </TableCell>

                                <TableCell style={{ margin: 0, padding: 0 }}>
                                  {ut?.risques?.map(
                                    (risque: any, index: any) => (
                                      <TableRow className="risqueencadrement">
                                        <TableCell className="risquecontent">
                                          {risque.libelle}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            display: "inline-block",
                                          }}
                                        >
                                          {risque?.descriptions?.map(
                                            (description: any) => (
                                              <TableRow className="descriptionencadrement">
                                                <TableCell className="descriptioncontent">
                                                  {description.description}
                                                </TableCell>
                                                <TableCell className="frequencecontent">
                                                  {description.frequence}
                                                </TableCell>
                                                <TableCell className="frequencecontent">
                                                  {description.gravite}
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    verticalAlign: "middle",
                                                  }}
                                                >
                                                  {description?.mesures.length >
                                                  0 ? (
                                                    description.mesures.map(
                                                      (mesure: any) => (
                                                        <TableRow
                                                          style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        >
                                                          <TableCell className="mesurecontent">
                                                            {mesure.libelle}
                                                          </TableCell>
                                                          <TableCell className="specificitecontent">
                                                            {mesure.specificite ||
                                                              " "}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )
                                                  ) : (
                                                    <TableRow
                                                      style={{
                                                        flex: 1,
                                                        height:
                                                          "100%!important",
                                                        display:
                                                          "flex!important",
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      <TableCell className="mesurecontent">
                                                        {""}
                                                      </TableCell>
                                                      <TableCell className="specificitecontent"></TableCell>
                                                    </TableRow>
                                                  )}
                                                </TableCell>
                                                <TableCell className="frequencecontent">
                                                  {RisqueCalcul.getTotalPonderations(
                                                    description
                                                  )}
                                                </TableCell>
                                                <TableCell className="frequencecontent">
                                                  {RisqueCalcul.getMaitrise(description)}
                                                </TableCell>
                                                <TableCell className="frequencecontent">
                                                  {RisqueCalcul.getCriticite(description)}
                                                </TableCell>
                                                <TableCell
                                                  className="frequencecontent"
                                                  style={{
                                                    backgroundColor:
                                                      RisqueCalcul.getPriorite(
                                                        description
                                                      ) === 1
                                                        ? "red"
                                                        : RisqueCalcul.getPriorite(
                                                            description
                                                          ) === 2
                                                        ? "orange"
                                                        : RisqueCalcul.getPriorite(
                                                            description
                                                          ) === 3
                                                        ? "yellow"
                                                        : "green",
                                                  }}
                                                >
                                                  {RisqueCalcul.getPriorite(description)}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableCell>
                              </div>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                  <button
                    className="hover_button boutonmiseajour"
                    onClick={() => {
                      console.log(
                        "clicked",
                        localStorage.getItem("idEntreprise")
                      );
                      client
                        .mutate({
                          mutation: UPDATE_DOCUMENT_UNIQUE,
                          variables: {
                            etablissementID:
                              localStorage.getItem("idEntreprise"),
                            dateedition: new Date().toISOString(),
                            isvalid: true,
                          },
                        })
                        .then((data) => {
                          window.alert("Document Unique mis à jour !");
                        });
                      // // TODO(): isvalid mutation
                    }}
                  >
                    Valider mon document
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentUniqueConsultation;
