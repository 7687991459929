import Navbar from "../../components/navbar";
import TopBar from "../../components/TopBar";

import "../../assets/css/profile.css";
import { TextField } from "@mui/material";
import AntSwitch from "../../components/AntSwitch";
import InputFile from "../../components/InputFile";
import * as Yup from "yup";

// import user query
import { useQuery, useMutation } from "@apollo/client";
import { GET_USER, UPDATE_ETABLISSEMENT } from "../../queries/user";
import {
  GET_AFFICHAGES,
  UPDATE_AFFICHAGE,
  UPDATE_LOGO,
} from "../../queries/affichages";
import { useEffect, useState } from "react";
import IUserData from "../../types/user";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";

const Settings = () => {
  const [monday, setMonday] = useState<boolean>(false);
  const [tuesday, setTuesday] = useState<boolean>(false);
  const [wednesday, setWednesday] = useState<boolean>(false);
  const [thursday, setThursday] = useState<boolean>(false);
  const [friday, setFriday] = useState<boolean>(false);
  const [saturday, setSaturday] = useState<boolean>(false);
  const [sunday, setSunday] = useState<boolean>(false);
  const [affichages, setAffichages] = useState<any>(null);
  const [metierID, setMetierID] = useState<string>("");
  const [logoUpdate, setLogoUpdate] = useState<boolean>(false);
  const [affichagesUpdate, setAffichagesUpdate] = useState<boolean>(false);
  const [etablissementUpdate, setEtablissementUpdate] =
    useState<boolean>(false);
  // get affichages
  const dataAffichages = useQuery(GET_AFFICHAGES, {
    variables: {
      id: localStorage.getItem(
        "CognitoIdentityServiceProvider.7jkln9l9s8lsv95r5okpu9ue4e.LastAuthUser"
      ),
    },
  });
  // query GET_USER with id from localStorage
  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      id: localStorage.getItem(
        "CognitoIdentityServiceProvider.7jkln9l9s8lsv95r5okpu9ue4e.LastAuthUser"
      ),
    },
  });
  const [createEtablissement, createEtablissementState] = useMutation(
    UPDATE_ETABLISSEMENT,
    {
      onCompleted: () => {
        console.log("etablissement updated");
        setEtablissementUpdate(true);
      },
    }
  );
  const [updateAffichage, updateAffichageState] = useMutation(
    UPDATE_AFFICHAGE,
    {
      onCompleted: () => {
        console.log("affichage updated");
        setAffichagesUpdate(true);
      },
    }
  );
  const [updateLogo, updateLogoState] = useMutation(UPDATE_LOGO, {
    onCompleted: () => {
      console.log("logo updated");
      setLogoUpdate(true);
    },
  });
  const [user, setUser] = useState<IUserData>();
  const [etablissement, setEtablissement] = useState<any>({});
  const [errorForm, setErrorForm] = useState<string>("");

  // get idEntreprise from localStorage
  const idEntreprise = localStorage.getItem("idEntreprise");

  const validationSchema = Yup.object({
    dirigeant: Yup.string().required("Champ(s) requis"),
    nbemployes: Yup.string().required("Champ(s) requis"),
    raisonsociale: Yup.string().required("Champ(s) requis"),
    siret: Yup.string()
      .required("Champ(s) requis")
      .matches(/^\d{14}$/, "SIRET invalide"),
    cp: Yup.string().required("Champ(s) requis").min(5, "Code postal invalide"),
    ville: Yup.string().required("Champ(s) requis"),
    adresse: Yup.string().required("Champ(s) requis"),
    telephone: Yup.string().required("Champ(s) requis"),
  });

  useEffect(() => {
    console.log("data :", data);
    if (!data) return;
    const user = data.utilisateur;
    setUser(user);
    const etablissement = user.etablissements.find(
      (etablissement: any) => etablissement.id === idEntreprise
    );
    setEtablissement(etablissement);
    setMetierID(etablissement.metier.id);
  }, [data, idEntreprise]);

  useEffect(() => {
    if (dataAffichages.data) {
      // find idEntreprise in dataAffichages.data.utilisateur.etablissements
      const etablissement = dataAffichages.data.utilisateur.etablissements.find(
        (etablissement: any) => etablissement.id === idEntreprise
      );
      setAffichages(etablissement);
      console.log("etablissement1 :", etablissement);
      if (etablissement.donnees.infosAffichage?.horairelundidebut !== "Fermé")
        setMonday(true);
      else setMonday(false);
      if (etablissement.donnees.infosAffichage?.horairemardidebut !== "Fermé")
        setTuesday(true);
      if (
        etablissement.donnees.infosAffichage?.horairemercredidebut !== "Fermé"
      )
        setWednesday(true);
      if (etablissement.donnees.infosAffichage?.horairejeudidebut !== "Fermé")
        setThursday(true);
      if (
        etablissement.donnees.infosAffichage?.horairevendredidebut !== "Fermé"
      )
        setFriday(true);
      if (etablissement.donnees.infosAffichage?.horairesamedidebut !== "Fermé")
        setSaturday(true);
      if (
        etablissement.donnees.infosAffichage?.horairedimanchedebut !== "Fermé"
      )
        setSunday(true);
    }
  }, [dataAffichages, idEntreprise]);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;
  if (createEtablissementState.loading) return <LoadingComponent />;
  if (createEtablissementState.error) return <ErrorComponent error={createEtablissementState.error} />;

  const updateEtablissement = (e: any) => {
    console.log("etablissement :", e);
    // e.preventDefault();
    createEtablissement({
      variables: {
        id: idEntreprise,
        userId: localStorage.getItem(
          "CognitoIdentityServiceProvider.7jkln9l9s8lsv95r5okpu9ue4e.LastAuthUser"
        ),
        dirigeant: e.variables.input.dirigeant,
        nbemployes: parseInt(e.variables.input.nbemployes),
        raisonsociale: e.variables.input.raisonsociale,
        siret: e.variables.input.siret,
        cp: e.variables.input.cp,
        ville: e.variables.input.ville,
        adresse: e.variables.input.adresse,
        telephone: e.variables.input.telephone,
        metierID: metierID,
      },
    });
  };

  const updateAffichages = (e: any) => {
    console.log("affichage :", e.donnees.infosAffichage);
    if (!monday) {
      e.donnees.infosAffichage.horairelundidebut = "Fermé";
      e.donnees.infosAffichage.horairelundifin = "";
    }
    if (!tuesday) {
      e.donnees.infosAffichage.horairemardidebut = "Fermé";
      e.donnees.infosAffichage.horairemardifin = "";
    }
    if (!wednesday) {
      e.donnees.infosAffichage.horairemercredidebut = "Fermé";
      e.donnees.infosAffichage.horairemercredifin = "";
    }
    if (!thursday) {
      e.donnees.infosAffichage.horairejeudidebut = "Fermé";
      e.donnees.infosAffichage.horairejeudifin = "";
    }
    if (!friday) {
      e.donnees.infosAffichage.horairevendredidebut = "Fermé";
      e.donnees.infosAffichage.horairevendredifin = "";
    }
    if (!saturday) {
      e.donnees.infosAffichage.horairesamedidebut = "Fermé";
      e.donnees.infosAffichage.horairesamedifin = "";
    }
    if (!sunday) {
      e.donnees.infosAffichage.horairedimanchedebut = "Fermé";
      e.donnees.infosAffichage.horairedimanchefin = "";
    }
    // e.preventDefault();
    updateAffichage({
      variables: {
        entrepriseID: idEntreprise,
        horairedimanchedebut: e.donnees.infosAffichage.horairedimanchedebut,
        horairedimanchefin: e.donnees.infosAffichage.horairedimanchefin,
        horairelundidebut: e.donnees.infosAffichage.horairelundidebut,
        horairelundifin: e.donnees.infosAffichage.horairelundifin,
        horairemardidebut: e.donnees.infosAffichage.horairemardidebut,
        horairemardifin: e.donnees.infosAffichage.horairemardifin,
        horairemercredidebut: e.donnees.infosAffichage.horairemercredidebut,
        horairemercredifin: e.donnees.infosAffichage.horairemercredifin,
        horairejeudidebut: e.donnees.infosAffichage.horairejeudidebut,
        horairejeudifin: e.donnees.infosAffichage.horairejeudifin,
        horairevendredidebut: e.donnees.infosAffichage.horairevendredidebut,
        horairevendredifin: e.donnees.infosAffichage.horairevendredifin,
        horairesamedidebut: e.donnees.infosAffichage.horairesamedidebut,
        horairesamedifin: e.donnees.infosAffichage.horairesamedifin,
        joursrepos: e.donnees.infosAffichage.joursrepos,
        derogation: e.donnees.infosAffichage.derogation,
        adressemedecine: e.donnees.infosAffichage.adressemedecine,
        numeromedecine: e.donnees.infosAffichage.numeromedecine,
        adresseinspection: e.donnees.infosAffichage.adresseinspection,
        numeroinspection: e.donnees.infosAffichage.numeroinspection,
        nominspection: e.donnees.infosAffichage.nominspection,
        convention: e.donnees.infosAffichage.convention,
      },
    });
  };

  const updateLogoCall = (e: any) => {
    console.log("logo :", e);

    // Convertir l'image base64 en objet Image
    const image = new Image();
    image.src = e;

    // Attendre que l'image soit chargée
    image.onload = () => {
      // Définir la largeur et la hauteur maximales autorisées
      const maxWidth = 200; // Largeur maximale souhaitée
      const maxHeight = 200; // Hauteur maximale souhaitée

      // Vérifier si l'image est plus grande que les dimensions maximales
      if (image.width > maxWidth || image.height > maxHeight) {
        // Calculer les nouvelles dimensions en conservant le rapport largeur/hauteur
        let newWidth = image.width;
        let newHeight = image.height;

        if (newWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = (maxWidth / image.width) * image.height;
        }

        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = (maxHeight / image.height) * image.width;
        }

        // Créer un canvas pour redimensionner l'image
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx: any = canvas.getContext("2d");

        // Dessiner l'image redimensionnée sur le canvas
        ctx.drawImage(image, 0, 0, newWidth, newHeight);

        // Obtenir l'image redimensionnée sous forme de base64
        const resizedImageBase64 = canvas.toDataURL("image/png");

        // Mettre à jour le logo avec l'image redimensionnée
        updateLogo({
          variables: {
            entrepriseID: idEntreprise,
            logo: resizedImageBase64,
          },
        })
          .then(() => {
            setLogoUpdate(true);
          })
          .catch((err) => {
            console.log("err :", err);
            setErrorForm(err.errors[0]);
          });
      } else {
        // L'image n'a pas besoin d'être redimensionnée, mettez à jour le logo directement
        updateLogo({
          variables: {
            entrepriseID: idEntreprise,
            logo: e,
          },
        })
          .then(() => {
            setLogoUpdate(true);
          })
          .catch((err) => {
            console.log("err :", err);
            setErrorForm(err.errors[0]);
          });
      }
    };
  };

  const deleteLogoCall = () => {
    console.log("delete logo");
    // e.preventDefault();
    updateLogo({
      variables: {
        entrepriseID: idEntreprise,
        logo: "",
      },
    });
  };

  return (
    <div>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Mon compte</h1>
          <div className="pageLine"></div>
          <div className="profile-title-section">
            <p>Renseigner ici les Informations de base de votre profil</p>
          </div>
          <div className="content">
            <div className="profile">
              {etablissement && (
                <>
                  <div className="profile-items">
                    <TextField
                      autoFocus
                      id="outlined-basic"
                      label="Dirigeant"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={etablissement.dirigeant}
                      onChange={(e) =>
                        setEtablissement({
                          ...etablissement,
                          dirigeant: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Nombre d'employés"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={etablissement.nbemployes}
                      onChange={(e) =>
                        setEtablissement({
                          ...etablissement,
                          nbemployes: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Raison sociale"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={etablissement.raisonsociale}
                      onChange={(e) =>
                        setEtablissement({
                          ...etablissement,
                          raisonsociale: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Siret"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={etablissement.siret}
                      onChange={(e) =>
                        setEtablissement({
                          ...etablissement,
                          siret: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="profile-line"></div>
                  <div className="profile-items">
                    <TextField
                      id="outlined-basic"
                      label="Adresse"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={etablissement.adresse}
                      onChange={(e) =>
                        setEtablissement({
                          ...etablissement,
                          adresse: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Ville"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={etablissement.ville}
                      onChange={(e) =>
                        setEtablissement({
                          ...etablissement,
                          ville: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Code Postal"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={etablissement.cp}
                      onChange={(e) =>
                        setEtablissement({
                          ...etablissement,
                          cp: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Numéro de téléphone"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={etablissement.telephone}
                      onChange={(e) =>
                        setEtablissement({
                          ...etablissement,
                          telephone: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {errorForm && (
            <span
              style={{
                backgroundColor: "#ff0000",
                padding: "7px",
                borderRadius: "5px",
                width: "fit-content",
                color: "#fff",
                fontSize: "12px",
                alignSelf: "center",
              }}
            >
              {errorForm}
            </span>
          )}
          {etablissementUpdate && (
            <span
              style={{
                backgroundColor: "#16a916",
                padding: "7px",
                borderRadius: "5px",
                width: "fit-content",
                color: "#fff",
                fontSize: "12px",
                alignSelf: "center",
              }}
            >
              Vos informations ont bien été enregistrées
            </span>
          )}
          <button
            className="button-style"
            onClick={() => {
              // validation schema before update
              validationSchema
                .validate({
                  dirigeant: etablissement.dirigeant,
                  nbemployes: etablissement.nbemployes,
                  raisonsociale: etablissement.raisonsociale,
                  siret: etablissement.siret,
                  cp: etablissement.cp,
                  ville: etablissement.ville,
                  adresse: etablissement.adresse,
                  telephone: etablissement.telephone,
                })
                .then((e) => {
                  console.log("e :", e);
                  updateEtablissement({
                    variables: {
                      id: idEntreprise,
                      input: {
                        ...etablissement,
                      },
                    },
                  });
                })
                .catch((err) => {
                  console.log("err :", err);
                  setErrorForm(err.errors[0]);
                });
            }}
          >
            Enregistrer
          </button>
          <div className="profile-title-section">
            {affichages && (
              <>
                <div
                  className="line"
                  style={{
                    width: "100%",
                  }}
                ></div>
                <p>
                  Renseigner ici les Informations de base de vos affichages
                  réglementaires
                </p>
                {logoUpdate && (
                  <span
                    style={{
                      backgroundColor: "#16a916",
                      padding: "7px",
                      borderRadius: "5px",
                      width: "fit-content",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    Votre logo a bien été mis à jour
                  </span>
                )}
                <p
                  style={{
                    color: "#5a5a5a",
                    fontSize: "15px",
                    fontWeight: "400",
                  }}
                >
                  Choisissez votre Logo :
                </p>
                <p
                  style={{
                    color: "#5a5a5aaa",
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                >
                  (formats .png, .jpg, .jpeg)
                </p>
                {affichages.donnees.infosAffichage ? (
                  <InputFile
                    logo={affichages.donnees.infosAffichage.logo}
                    onLogoChange={updateLogoCall}
                  />
                ) : (
                  <InputFile logo="" onLogoChange={updateLogoCall} />
                )}
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    deleteLogoCall();
                  }}
                >
                  Supprimer mon logo
                </button>
              </>
            )}
          </div>
          <div className="content">
            {affichages && (
              <>
                <div className="profile">
                  <div className="profile-items">
                    Lundi :
                    <div className="profile-item">
                      <AntSwitch
                        onChange={() => setMonday(!monday)}
                        checked={monday}
                      />
                      {monday && (
                        <>
                          <TextField
                            id="outlined-basic"
                            label="De"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairelundidebut
                                ? affichages.donnees.infosAffichage
                                    ?.horairelundidebut
                                : ""
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairelundidebut: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          <TextField
                            id="outlined-basic"
                            label="A"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage?.horairelundifin
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairelundifin: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                    Mardi :
                    <div className="profile-item">
                      <AntSwitch
                        onChange={() => setTuesday(!tuesday)}
                        checked={tuesday}
                      />
                      {tuesday && (
                        <>
                          <TextField
                            id="outlined-basic"
                            label="De"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairemardidebut
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairemardidebut: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          <TextField
                            id="outlined-basic"
                            label="A"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage?.horairemardifin
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairemardifin: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                    Mercredi :
                    <div className="profile-item">
                      <AntSwitch
                        onChange={() => setWednesday(!wednesday)}
                        checked={wednesday}
                      />
                      {wednesday && (
                        <>
                          <TextField
                            id="outlined-basic"
                            label="De"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairemercredidebut
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairemercredidebut: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          <TextField
                            id="outlined-basic"
                            label="A"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairemercredifin
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairemercredifin: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                    Jeudi :
                    <div className="profile-item">
                      <AntSwitch
                        onChange={() => setThursday(!thursday)}
                        checked={thursday}
                      />
                      {thursday && (
                        <>
                          <TextField
                            id="outlined-basic"
                            label="De"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairejeudidebut
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairejeudidebut: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          <TextField
                            id="outlined-basic"
                            label="A"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage?.horairejeudifin
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairejeudifin: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                    Vendredi :
                    <div className="profile-item">
                      <AntSwitch
                        onChange={() => setFriday(!friday)}
                        checked={friday}
                      />
                      {friday && (
                        <>
                          <TextField
                            id="outlined-basic"
                            label="De"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairevendredidebut
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairevendredidebut: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          <TextField
                            id="outlined-basic"
                            label="A"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairevendredifin
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairevendredifin: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                    Samedi :
                    <div className="profile-item">
                      <AntSwitch
                        onChange={() => setSaturday(!saturday)}
                        checked={saturday}
                      />
                      {saturday && (
                        <>
                          <TextField
                            id="outlined-basic"
                            label="De"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairesamedidebut
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairesamedidebut: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          <TextField
                            id="outlined-basic"
                            label="A"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairesamedifin
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairesamedifin: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                    Dimanche :
                    <div className="profile-item">
                      <AntSwitch
                        onChange={() => setSunday(!sunday)}
                        checked={sunday}
                      />
                      {sunday && (
                        <>
                          <TextField
                            id="outlined-basic"
                            label="De"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairedimanchedebut
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairedimanchedebut: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          <TextField
                            id="outlined-basic"
                            label="A"
                            variant="outlined"
                            size="small"
                            className="style-textfield2"
                            value={
                              affichages.donnees.infosAffichage
                                ?.horairedimanchefin
                            }
                            onChange={(e) =>
                              setAffichages({
                                ...affichages,
                                donnees: {
                                  ...affichages.donnees,
                                  infosAffichage: {
                                    ...affichages.donnees.infosAffichage,
                                    horairedimanchefin: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="profile-line"></div>
                  <div className="profile-items">
                    <TextField
                      autoFocus
                      id="outlined-basic"
                      label="Horaire de la pause déjeuner"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={affichages.donnees.infosAffichage?.joursrepos}
                      onChange={(e) =>
                        setAffichages({
                          ...affichages,
                          donnees: {
                            ...affichages.donnees,
                            infosAffichage: {
                              ...affichages.donnees.infosAffichage,
                              joursrepos: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Dérogation aux horaires de travail"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={affichages.donnees.infosAffichage?.derogation}
                      onChange={(e) =>
                        setAffichages({
                          ...affichages,
                          donnees: {
                            ...affichages.donnees,
                            infosAffichage: {
                              ...affichages.donnees.infosAffichage,
                              derogation: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Adresse médecine du travail"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={affichages.donnees.infosAffichage?.adressemedecine}
                      onChange={(e) =>
                        setAffichages({
                          ...affichages,
                          donnees: {
                            ...affichages.donnees,
                            infosAffichage: {
                              ...affichages.donnees.infosAffichage,
                              adressemedecine: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Numéro médecine du travail"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={affichages.donnees.infosAffichage?.numeromedecine}
                      onChange={(e) =>
                        setAffichages({
                          ...affichages,
                          donnees: {
                            ...affichages.donnees,
                            infosAffichage: {
                              ...affichages.donnees.infosAffichage,
                              numeromedecine: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Adresse inspection du travail"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={
                        affichages.donnees.infosAffichage?.adresseinspection
                      }
                      onChange={(e) =>
                        setAffichages({
                          ...affichages,
                          donnees: {
                            ...affichages.donnees,
                            infosAffichage: {
                              ...affichages.donnees.infosAffichage,
                              adresseinspection: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Numéro inspection du travail"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={
                        affichages.donnees.infosAffichage?.numeroinspection
                      }
                      onChange={(e) =>
                        setAffichages({
                          ...affichages,
                          donnees: {
                            ...affichages.donnees,
                            infosAffichage: {
                              ...affichages.donnees.infosAffichage,
                              numeroinspection: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Nom inspection du travail"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={affichages.donnees.infosAffichage?.nominspection}
                      onChange={(e) =>
                        setAffichages({
                          ...affichages,
                          donnees: {
                            ...affichages.donnees,
                            infosAffichage: {
                              ...affichages.donnees.infosAffichage,
                              nominspection: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Intitulé de la convention collective"
                      variant="outlined"
                      size="small"
                      className="style-textfield"
                      value={affichages.donnees.infosAffichage?.convention}
                      onChange={(e) =>
                        setAffichages({
                          ...affichages,
                          donnees: {
                            ...affichages.donnees,
                            infosAffichage: {
                              ...affichages.donnees.infosAffichage,
                              convention: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {affichagesUpdate && (
            <span
              style={{
                backgroundColor: "#16a916",
                padding: "7px",
                borderRadius: "5px",
                width: "fit-content",
                color: "#fff",
                fontSize: "12px",
                alignSelf: "center",
              }}
            >
              Vos informations ont bien été enregistrées
            </span>
          )}
          <button
            className="button-style"
            onClick={() => {
              updateAffichages({
                ...affichages,
                donnees: {
                  ...affichages.donnees,
                  infosAffichage: {
                    ...affichages.donnees.infosAffichage,
                    horairelundidebut:
                      affichages.donnees.infosAffichage.horairelundidebut,
                    horairelundifin:
                      affichages.donnees.infosAffichage.horairelundifin,
                    horairemardidebut:
                      affichages.donnees.infosAffichage.horairemardidebut,
                    horairemardifin:
                      affichages.donnees.infosAffichage.horairemardifin,
                    horairemercredidebut:
                      affichages.donnees.infosAffichage.horairemercredidebut,
                    horairemercredifin:
                      affichages.donnees.infosAffichage.horairemercredifin,
                    horairejeudidebut:
                      affichages.donnees.infosAffichage.horairejeudidebut,
                    horairejeudifin:
                      affichages.donnees.infosAffichage.horairejeudifin,
                    horairevendredidebut:
                      affichages.donnees.infosAffichage.horairevendredidebut,
                    horairevendredifin:
                      affichages.donnees.infosAffichage.horairevendredifin,
                    horairesamedidebut:
                      affichages.donnees.infosAffichage.horairesamedidebut,
                    horairesamedifin:
                      affichages.donnees.infosAffichage.horairesamedifin,
                    horairedimanchedebut:
                      affichages.donnees.infosAffichage.horairedimanchedebut,
                    horairedimanchefin:
                      affichages.donnees.infosAffichage.horairedimanchefin,
                    joursrepos: affichages.donnees.infosAffichage.joursrepos,
                    derogation: affichages.donnees.infosAffichage.derogation,
                    adressemedecine:
                      affichages.donnees.infosAffichage.adressemedecine,
                    numeromedecine:
                      affichages.donnees.infosAffichage.numeromedecine,
                    adresseinspection:
                      affichages.donnees.infosAffichage.adresseinspection,
                    numeroinspection:
                      affichages.donnees.infosAffichage.numeroinspection,
                    nominspection:
                      affichages.donnees.infosAffichage.nominspection,
                    convention: affichages.donnees.infosAffichage.convention,
                  },
                },
              });
            }}
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
