import { CognitoUserPool } from "amazon-cognito-identity-js";

const config = {
  region: "eu-central-1",
  UserPoolId: "eu-central-1_LKOvahkqY",
  ClientId: "7jkln9l9s8lsv95r5okpu9ue4e",
};

const userPool = new CognitoUserPool(config);

export default userPool;
