import TopBar from "../../../components/TopBar";
import Navbar from "../../../components/navbar";

import "../../../assets/css/docu.css";

import { useMutation } from "@apollo/client";
import {
  LINKS_ETABLISSMENT_MUTATION,
  USER_ID_LS_KEY,
} from "../../../queries/uniteTravail";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingComponent from "../../../components/Loading";
import ErrorComponent from "../../../components/ErrorComponent";
import { UPDATE_DOCUMENT_UNIQUE } from "../../../queries/documentUnique";
import API_URL from "../../../utils/api";
import { uniqBy } from "lodash";

const Mesures = () => {
  const oldModel = useRef([] as any);
  const model = useRef([] as any);
  const [loading, setLoading] = useState(true);
  const [pageToSwitch, setPageToSwitch] = useState("");
  const mutationQueue = useRef(undefined as any);

  useEffect(() => {
    fetch(
      `${API_URL}/rest/user/model/${localStorage.getItem(
        USER_ID_LS_KEY
      )}/${localStorage.getItem("idEntreprise")}`
    ).then((res) => {
      console.log(res);
      res.json().then((data) => {
        data = data
          .filter((ut: any) => ut.checked)
          .map((ut: any) => {
            ut.risques = ut.risques
              .filter((risque: any) => risque.checked)
              .map((risque: any) => {
                risque.descriptions = risque.descriptions.filter(
                  (description: any) => description.checked
                );
                return risque;
              });
            return ut;
          });
        model.current = data;
        oldModel.current = data;
        setLoading(false);
      });
    });
  }, []);

  const [mutateDocUnique, docUniqueMutation] = useMutation(
    UPDATE_DOCUMENT_UNIQUE
  );

  const [linksMutation, mutation] = useMutation(LINKS_ETABLISSMENT_MUTATION);

  const mutate = (data: any) => {
    console.log(data);
    if (mutation.loading) {
      mutationQueue.current = {
        linksAdd: [
          ...(mutationQueue.current?.linksAdd.filter(
            (item: any) =>
              !data.linksDelete.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              ) &&
              !data.linksAdd.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              )
          ) ?? []),
          ...data.linksAdd,
        ],
        linksDelete: [
          ...(mutationQueue.current?.linksDelete.filter(
            (item: any) =>
              !data.linksAdd.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              ) &&
              !data.linksDelete.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              )
          ) ?? []),
          ...data.linksDelete,
        ],
      };
    } else {
      linksMutation({
        variables: {
          id: localStorage.getItem("idEntreprise"),
          linksAdd: data.linksAdd,
          linksDelete: data.linksDelete,
        },
      });
    }
  };

  useEffect(() => {
    if (!mutation.loading && mutationQueue.current != undefined) {
      let toMutate = mutationQueue.current;
      mutationQueue.current = undefined;
      linksMutation({
        variables: {
          id: localStorage.getItem("idEntreprise"),
          linksAdd: toMutate.linksAdd,
          linksDelete: toMutate.linksDelete,
        },
      }).then(async () => {
        if (pageToSwitch != "" && mutationQueue.current == undefined) {
          window.location.href = pageToSwitch;
        }
      });
    } else {
      if (pageToSwitch != "") {
        window.location.href = pageToSwitch;
      }
    }
  }, [mutation]);

  const content = () => (
    <>
      {model.current.map((ut: any, indexUt: number) =>
        ut.risques.map((risque: any, indexRisque: number) =>
          risque.descriptions.map((description: any, indexDesc: number) => (
            <Accordion
              className="accordion"
              key={indexUt * 1000 + indexRisque * 100 + indexDesc}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">
                      {ut.libelle + " | " + risque.libelle}
                    </Typography>
                  </Grid>
                  <Typography variant="subtitle1" color="textSecondary">
                    {description.description}
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} className="table_risques">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              Mesures préventives disponibles
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {description.mesures.map(
                            (mesure: any, indexMesure: number) => (
                              <TableRow key={indexMesure}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: "20%" }}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={mesure.checked || false}
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    onChange={(event) => {
                                      console.log(model);
                                      console.log(mesure);
                                      mesure.checked = event.target.checked;
                                      mutate({
                                        linksAdd: mesure.checked
                                          ? [
                                              {
                                                uniteTravailID: ut.id,
                                                risque: { id: description.id },
                                                mesure: {
                                                  id: mesure.id,
                                                  ponderation: Number(
                                                    mesure.ponderation
                                                  ),
                                                  specificite:
                                                    mesure.specificite,
                                                },
                                              },
                                            ]
                                          : [],
                                        linksDelete: !mesure.checked
                                          ? [
                                              {
                                                uniteTravailID: ut.id,
                                                risque: { id: risque.id },
                                                mesure: { id: mesure.id },
                                              },
                                            ]
                                          : [],
                                      });
                                    }}
                                  />
                                  {mesure.libelle}
                                  <TextField
                                    id="outlined-basic"
                                    label="Spécificités"
                                    variant="outlined"
                                    style={{
                                      float: "right",
                                    }}
                                    defaultValue={mesure.specificite}
                                    inputProps={{
                                      maxLength: 150,
                                    }}
                                    onChange={(event) => {
                                      mesure.specificite = event.target.value;
                                      if (mesure.checked) {
                                        console.log(mesure.specificite);
                                        mutate({
                                          linksAdd: [
                                            {
                                              uniteTravailID: ut.id,
                                              risque: { id: description.id },
                                              mesure: {
                                                id: mesure.id,
                                                ponderation: Number(
                                                  mesure.ponderation
                                                ),
                                                specificite: mesure.specificite,
                                              },
                                            },
                                          ],
                                          linksDelete: [],
                                        });
                                      }
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        )
      )}
    </>
  );

  if (loading) return <LoadingComponent />;

  if (mutation.loading && pageToSwitch != "") return <LoadingComponent />;
  if (mutation.error) return <ErrorComponent error={mutation.error} />;

  if (docUniqueMutation.loading) return <LoadingComponent />;
  if (docUniqueMutation.error)
    return <ErrorComponent error={docUniqueMutation.error} />;
  return (
    <>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Sélection des mesures préventives</h1>
          <div className="pageLine"></div>
          <div className="affichages">
            <p>
              Voici les mesures préventives les plus fréquemment identifiées par
              description de risque pour chaque risque.
            </p>
            <p>
              Cochez la case si la mesure préventive est en place dans votre
              établissement.
            </p>
            <p>
              Si vous estimez qu'il manque une mesure préventive sur une
              description de risque ou qu'il est possible de la remplacer,
              n'hésitez pas à l'ajouter via le plan d'actions.
            </p>
            <button
              onClick={async () => {
                let toMutate = uniqBy(
                  model.current.reduce((acc: [], ut: any) => {
                    return [
                      ...acc,
                      ...ut.risques.reduce((acc2: [], risque: any) => {
                        return [
                          ...acc2,
                          ...risque.descriptions.reduce(
                            (acc3: [], description: any) => {
                              description.checked = true;
                              return [
                                ...acc3,
                                ...description.mesures.reduce(
                                  (acc4: [], mesure: any) => {
                                    mesure.checked = true;
                                    return [
                                      ...acc4,
                                      {
                                        uniteTravailID: ut.id,
                                        risque: { id: description.id },
                                        mesure: {
                                          id: mesure.id,
                                          ponderation: Number(
                                            mesure.ponderation
                                          ),
                                          specificite: mesure.specificite,
                                        },
                                      },
                                    ];
                                  },
                                  []
                                ),
                              ];
                            },
                            []
                          ),
                        ];
                      }, []),
                    ];
                  }, []),
                  (item: any) => JSON.stringify(item)
                );
                for (let i = 0; i < toMutate.length; i += 25) {
                  mutate({
                    linksAdd: toMutate.slice(i, i + 25),
                    linksDelete: [],
                  });
                  // wait 2 sec
                  await new Promise((resolve) => setTimeout(resolve, 2000));
                }
                console.log("end");
              }}
            >
              Selectionner tout
            </button>
            {content()}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="button-style-doc"
                onClick={async () => {
                  await mutateDocUnique({
                    variables: {
                      etablissementID: localStorage.getItem("idEntreprise"),
                      isvalid: false,
                      avancement: 3,
                    },
                  });
                  window.location.href = "/description-des-risques";
                }}
              >
                Précédent
              </button>
              <button
                className="button-style-doc"
                onClick={async () => {
                  window.location.href = "/consulter-mon-document-unique";
                }}
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mesures;
