import TopBar from "../../../components/TopBar";
import Navbar from "../../../components/navbar";

// import appolo
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_WORKSGROUPS,
  PUT_WORKSGROUPS,
  DEL_WORKSGROUPS,
} from "../../../queries/worksgroups";

import "../../../assets/css/docu.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../components/Loading";
import ErrorComponent from "../../../components/ErrorComponent";
import { UPDATE_DOCUMENT_UNIQUE } from "../../../queries/documentUnique";

const WorkGroups = () => {
  const [newWorkgroupName, setNewWorkgroupName] = useState("");
  const [newWorkgroupFunction, setNewWorkgroupFunction] = useState("");
  const { data, loading, error } = useQuery(GET_WORKSGROUPS, {
    variables: {
      id: localStorage.getItem(
        "CognitoIdentityServiceProvider.7jkln9l9s8lsv95r5okpu9ue4e.LastAuthUser"
      ),
    },
  });

  const [mutateDocUnique, docUniqueMutation] = useMutation(
    UPDATE_DOCUMENT_UNIQUE
  );

  const [worksgroups, setWorksgroups] = useState<any>([]);
  const [workgroupMutation, mutationState] = useMutation(PUT_WORKSGROUPS);
  const [delWorkgroupMutation, delMutationState]: any =
    useMutation(DEL_WORKSGROUPS);

  // get idEntreprise from localstorage
  useEffect(() => {
    localStorage.getItem("idEntreprise");
    if (data) {
      let wg = data.utilisateur.etablissements.filter(
        (worksgroup: any) =>
          worksgroup.id === localStorage.getItem("idEntreprise")
      )[0];
      setWorksgroups(wg.donnees.workGroup);
      // console.log("worksgroups", worksgroups);
    }
  }, [data]);
  console.log("worksgroups", worksgroups);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error}/>;

  const handleChangeName = (e: any) => {
    setNewWorkgroupName(e.target.value);
  };

  const handleChangeFunction = (e: any) => {
    setNewWorkgroupFunction(e.target.value);
  };

  const handleSubmit = () => {
    console.log(newWorkgroupName);
    console.log(newWorkgroupFunction);
    // add new workgroup to the list via PUT_WORKSGROUPS
    // setWorksgroups([...worksgroups, newWorkgroupName]);

    console.log(localStorage.getItem("idEntreprise"));

    // add new workgroup to the database via PUT_WORKSGROUPS
    workgroupMutation({
      variables: {
        id: localStorage.getItem("idEntreprise"),
        participants: [
          {
            nom: newWorkgroupName,
            fonction: newWorkgroupFunction,
          },
        ],
      },
    }).then(() => {
      // reset the input
      setNewWorkgroupName("");
      setNewWorkgroupFunction("");

      // refresh the page
      window.location.reload();
    });
  };

  const deleteItem = (id: any) => {
    console.log("id", id);
    // delete workgroup from the list
    delWorkgroupMutation({
      variables: {
        id: localStorage.getItem("idEntreprise"),
        participants: [id],
      },
    }).then(() => {
      // refresh the page
      window.location.reload();
    });
  };

  if (docUniqueMutation.loading) return <LoadingComponent />;
  if (docUniqueMutation.error) return <ErrorComponent error={docUniqueMutation.error}/>;

  return (
    <>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Constitution du groupe de travail</h1>
          <div className="pageLine"></div>
          <div className="affichages">
            <TableContainer component={Paper} style={{ marginTop: "50px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      Nom des participants au document unique
                    </TableCell>
                    <TableCell align="center">
                      Fonction dans l'Entreprise
                    </TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {worksgroups &&
                    worksgroups.participants &&
                    worksgroups.participants.map(
                      (participant: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row" align="center">
                            {participant.nom}
                          </TableCell>
                          <TableCell align="center">
                            {participant.fonction}
                          </TableCell>
                          <TableCell align="center">
                            <DeleteOutlineIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                deleteItem(participant.id);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  <TableRow>
                    <TableCell component="th" scope="row" align="center">
                      <TextField
                        id="nom"
                        variant="outlined"
                        name="nom"
                        inputProps={{
                          style: {
                            padding: 15,
                          },
                        }}
                        value={newWorkgroupName}
                        onChange={handleChangeName}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        id="fonction"
                        variant="outlined"
                        name="fonction"
                        inputProps={{
                          style: {
                            padding: 15,
                          },
                        }}
                        value={newWorkgroupFunction}
                        onChange={handleChangeFunction}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <button
                        className="button-style-doc"
                        style={{ width: "90%" }}
                        onClick={handleSubmit}
                      >
                        Ajouter
                      </button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="button-style-doc"
                onClick={() => {
                  window.location.href = "/mon-document-unique";
                }}
              >
                Précédent
              </button>
              <button
                className="button-style-doc"
                onClick={async () => {
                  await mutateDocUnique({
                    variables: {
                      etablissementID: localStorage.getItem("idEntreprise"),
                      isvalid: false,
                      avancement: 1,
                    },
                  });
                  window.location.href = "/unites-de-travail";
                }}
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkGroups;
