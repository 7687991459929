import React, { useEffect, useState } from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../queries/user";
import { GET_AFFICHAGES } from "../queries/affichages";
import { CirclePicker, MaterialPicker } from "react-color";
import colors from "../assets/css/palettecouleurs";
import { Font } from "@react-pdf/renderer";
import "../assets/css/affichageeditor.css";

//Import des affichages PDF
import ARDiscrim from "./documents/AR_Discrim";
import ARFumer from "./documents/AR_Fumer";
import ARHarcel from "./documents/AR_Harcel";
import ARConges from "./documents/AR_Conges";
import ARCSE from "./documents/AR_CSE";
import ARElec from "./documents/AR_Elec";
import Arelecverif from "./documents/AR_Elec_Verif";
import ARInfos from "./documents/AR_Infos";
import ARMarianne from "./documents/AR_Marianne";
import ARMarianneExplications from "./documents/AR_Marianne_Explications";
import Tousreglementaires from "./documents/tous_reglementaires";
import ARSecurite from "./documents/AR_Securite";
import AEDistance from "./documents/AE_Distance";
import AEMasque from "./documents/AE_Masque";
import ASBoucherieListe from "./documents/AS_Boucherie_Liste";
import ASBoulangerListe from "./documents/AS_Boucherie_Liste";
import ASBTPChomage from "./documents/AS_BTP_Chomage";
import ASBTPListe from "./documents/AS_BTP_Liste";
import ASRestaurantListe1 from "./documents/AS_Restaurant_Liste1";
import ASRestaurantListe2 from "./documents/AS_Restaurant_Liste2";
import ASAE from "./documents/AS_AE";

import useAnalyticsEventTracker from "./analytics";
import LoadingComponent from "./Loading";
import ErrorComponent from "./ErrorComponent";
import { useMarqueBlanche } from "../context/MarqueBlanche";

interface PDFGeneratorProps {
  type: string;
}

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const PDFGenerator: React.FC<PDFGeneratorProps> = ({ type }) => {
  const gaEventTracker = useAnalyticsEventTracker("Affichages");
  const [colorText, setColorText] = useState<string>("#FFFFFF");
  const [colorContour, setColorContour] = useState<string>("#43425D");
  const [colorFond, setColorFond] = useState<string>("#B0B0B0");
  const [colorRadius, setColorRadius] = useState<number>(12);
  const [optionsSelected, setOptionsSelected] = useState<string>("CHOISIR");
  const [pdfGenerated, setPdfGenerated] = useState<boolean>(false);
  const [, setEtablissement] = useState<any>(null);
  const { data, loading, error } = useQuery(GET_USER, {
    variables: {
      id: localStorage.getItem(
        "CognitoIdentityServiceProvider.7jkln9l9s8lsv95r5okpu9ue4e.LastAuthUser"
      ),
    },
  });
  const {
    data: dataAffichages,
    loading: loadingAffichages,
    error: errorAffichages,
  } = useQuery(GET_AFFICHAGES, {
    variables: {
      id: localStorage.getItem(
        "CognitoIdentityServiceProvider.7jkln9l9s8lsv95r5okpu9ue4e.LastAuthUser"
      ),
    },
  });
  const [metier, setMetier] = useState<string>("");
  const [affichage, setAffichage] = useState<any>(null);
  const { logoPath } = useMarqueBlanche();
  // useEffect with metier as dependency
  useEffect(() => {
    if (data && dataAffichages) {
      const etablissement = data.utilisateur.etablissements.find(
        (etablissement: any) =>
          etablissement.id === localStorage.getItem("idEntreprise")
      );
      const etablissementAffichage =
        dataAffichages.utilisateur.etablissements.find(
          (etablissement: any) =>
            etablissement.id === localStorage.getItem("idEntreprise")
        );
      console.log("etablissement :", etablissement);
      console.log("etablissementAffichage :", etablissementAffichage);
      setEtablissement(etablissement);
      setAffichage(etablissementAffichage);
      if (etablissement.metier) {
        setMetier(etablissement.metier.libelle);
      }
    }
  }, [data, dataAffichages]);


  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error}  />;
  if (loadingAffichages) return <LoadingComponent />;
  if (errorAffichages) return <ErrorComponent error={errorAffichages} />;

  const defineOptions = () => {
    if (type === "reglementaire") {
      return (
        <select onChange={(event) => setOptionsSelected(event.target.value)}>
          <option value="CHOISIR">CHOISIR</option>
          <option value="AR_Discrim">Discrimination</option>
          <option value="AR_Harcel">Harcèlement</option>
          <option value="AR_Conges">Congés</option>
          <option value="AR_CSE">CSE</option>
          <option value="AR_Elec">Electricité annuelle</option>
          <option value="AR_Elec_Verif">Electricité mensuelle</option>
          <option value="AR_Fumer">Interdiction Fumer</option>
          <option value="AR_Infos">Infos Réglementaires</option>
          <option value="AR_Marianne">Marianne</option>
          <option value="AR_Securite">AR-Sécurité</option>
        </select>
      );
    } else if (type === "specifique") {
      console.log("metier :", metier);
      if (metier === "Boucherie") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_Boucherie_Liste">Liste Boucherie</option>
          </select>
        );
      } else if (metier === "Entreprise adaptée") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_EA_Liste">Entreprise Adaptée</option>
          </select>
        );
      } else if (metier === "Plombier") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_BTP_Chomage">Chômage</option>
            <option value="AS_BTP_Liste">Liste BTP</option>
          </select>
        );
      } else if (metier === "Platrier / Plaquiste") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_BTP_Chomage">Chômage</option>
            <option value="AS_BTP_Liste">Liste BTP</option>
          </select>
        );
      } else if (metier === "Electricien") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_BTP_Chomage">Chômage</option>
            <option value="AS_BTP_Liste">Liste BTP</option>
          </select>
        );
      } else if (metier === "Terrassement et travaux annexes") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_BTP_Chomage">Chômage</option>
            <option value="AS_BTP_Liste">Liste BTP</option>
          </select>
        );
      } else if (metier === "Facadier") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_BTP_Chomage">Chômage</option>
            <option value="AS_BTP_Liste">Liste BTP</option>
          </select>
        );
      } else if (metier === "Maçon/Carreleur") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_BTP_Chomage">Chômage</option>
            <option value="AS_BTP_Liste">Liste BTP</option>
          </select>
        );
      } else if (metier === "Peintre") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_BTP_Chomage">Chômage</option>
            <option value="AS_BTP_Liste">Liste BTP</option>
          </select>
        );
      } else if (metier === "Paysagiste") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_BTP_Chomage">Chômage</option>
            <option value="AS_BTP_Liste">Liste BTP</option>
          </select>
        );
      } else if (metier === "Restauration Rapide") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_Restaurant_Liste1">Liste 1 restauration</option>
            <option value="AS_Restaurant_Liste2">Liste 2 restauration</option>
            <option value="AS_Protection_Mineurs">Protection mineurs</option>
          </select>
        );
      } else if (metier === "Boucherie Charcuterie Traiteur") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_Restaurant_Liste1">Liste 1 restauration</option>
            <option value="AS_Restaurant_Liste2">Liste 2 restauration</option>
            <option value="AS_Protection_Mineurs">Protection mineurs</option>
          </select>
        );
      } else if (metier === "Bar / Restaurant") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_Restaurant_Liste1">Liste 1 restauration</option>
            <option value="AS_Restaurant_Liste2">Liste 2 restauration</option>
            <option value="AS_Protection_Mineurs">Protection mineurs</option>
          </select>
        );
      } else if (metier === "Boulanger Pâtissier") {
        return (
          <select onChange={(event) => setOptionsSelected(event.target.value)}>
            <option value="CHOISIR">CHOISIR</option>
            <option value="AS_Boulangerie_Liste">Liste Boulangerie</option>
          </select>
        );
      }
    } else {
      return (
        <select onChange={(event) => setOptionsSelected(event.target.value)}>
          <option value="CHOISIR">CHOISIR</option>
          <option value="AE_Distance">COVID Distance</option>
          <option value="AE_Masque">COVID Masque</option>
          {/* <option value="AE_Nettoyage">COVID Nettoyage</option>
          <option value="AE_Planning">COVID Planning</option>
          <option value="AE_Produit">COVID Produit</option>
          <option value="AE_Regle_1">COVID Regles 1</option>
          <option value="AE_Regle_2">COVID Regles 2</option>
          <option value="AE_Regle_3">COVID Regles 3</option>
          <option value="AE_Toilettes">COVID Toilettes</option> */}
        </select>
      );
    }
  };

  const MyDoc = () => {
    console.log("optionsSelected", optionsSelected);

    if (optionsSelected === "AR_Discrim") {
      gaEventTracker("Discrimination");
      return (
        <ARDiscrim
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_Harcel") {
      gaEventTracker("Harcèlement");
      return (
        <ARHarcel
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_Conges") {
      gaEventTracker("Congés");
      return (
        <ARConges
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_CSE") {
      gaEventTracker("CSE");
      return (
        <ARCSE
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_Elec") {
      gaEventTracker("Electricité Annuel");
      return (
        <ARElec
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_Infos") {
      gaEventTracker("Infos règlementaires");
      return (
        <ARInfos
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_Elec_Verif") {
      gaEventTracker("Electricité mensuelle");
      return (
        <Arelecverif
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_Fumer") {
      gaEventTracker("Interdiction fumer");
      return (
        <ARFumer
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_Marianne") {
      gaEventTracker("Marianne");
      return (
        <ARMarianne
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_Marianne_Explications") {
      gaEventTracker("Marianne explications");
      return (
        <ARMarianneExplications
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AR_Securite") {
      gaEventTracker("Sécurité");
      return (
        <ARSecurite
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "Tous_Reglementaires") {
      gaEventTracker("Tous documents");
      return (
        <Tousreglementaires
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AE_Distance") {
      gaEventTracker("COVID Distance");
      return (
        <AEDistance
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AE_Masque") {
      gaEventTracker("COVID Masque");
      return (
        <AEMasque
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AS_Boucherie_Liste") {
      gaEventTracker("Boucherie");
      return (
        <ASBoucherieListe
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AS_Boulangerie_Liste") {
      gaEventTracker("Boulangerie");
      return (
        <ASBoulangerListe
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AS_BTP_Chomage") {
      gaEventTracker("BTP Chômage");
      return (
        <ASBTPChomage
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AS_BTP_Liste") {
      gaEventTracker("BTP Liste");
      return (
        <ASBTPListe
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AS_Restaurant_Liste1") {
      gaEventTracker("Restaurant");
      return (
        <ASRestaurantListe1
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AS_Restaurant_Liste2") {
      return (
        <ASRestaurantListe2
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AS_EA_Liste") {
      gaEventTracker("Entreprise adaptée");
      return (
        <ASAE
          contourColor={colorContour}
          textColor={colorText}
          backgroundColor={colorFond}
          radius={colorRadius}
          etablissementAffichages={affichage}
          logo={logoPath || ""}
        />
      );
    } else if (optionsSelected === "AS_Protection_Mineurs") {
      gaEventTracker("Protection Mineurs");

      return null;
    } else {
      return null;
    }
  };

  const handleClick = () => {
    setPdfGenerated(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <p style={{ fontSize: "1rem", marginRight: "10px" }}>
          1. Sélectionnez l’affichage de votre choix
        </p>
        {defineOptions()}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-evenly",
          marginTop: "25px",
        }}
      >
        <div>
          {optionsSelected !== "CHOISIR" ? (
            <div className="affichagescontentright">
              <>
                <p>2. Sélectionnez la couleur du texte :</p>
                <CirclePicker
                  color={colorText}
                  onChange={(selectedColor) => setColorText(selectedColor.hex)}
                  circleSize={20}
                  circleSpacing={10}
                  colors={colors}
                  className="materialcircle"
                />
                <MaterialPicker
                  color={colorText}
                  onChange={(selectedColor) => setColorText(selectedColor.hex)}
                />
                <p>3. Sélectionnez la couleur du contour :</p>
                <CirclePicker
                  color={colorContour}
                  onChange={(selectedColor) =>
                    setColorContour(selectedColor.hex)
                  }
                  circleSize={20}
                  circleSpacing={10}
                  colors={colors}
                  className="materialcircle"
                />
                <MaterialPicker
                  color={colorContour}
                  onChange={(selectedColor) =>
                    setColorContour(selectedColor.hex)
                  }
                />
                <p>4. Sélectionnez la couleur du fond :</p>
                <CirclePicker
                  color={colorFond}
                  onChange={(selectedColor) => setColorFond(selectedColor.hex)}
                  circleSize={20}
                  circleSpacing={10}
                  colors={colors}
                  className="materialcircle"
                />
                <MaterialPicker
                  color={colorFond}
                  onChange={(selectedColor) => setColorFond(selectedColor.hex)}
                />
                <p>5. Sélectionnez la taille des arrondis</p>
                <input
                  type="range"
                  min="0"
                  max="50"
                  value={colorRadius}
                  onChange={(e) => setColorRadius(parseInt(e.target.value))}
                  className="materialcircle"
                />
              </>
            </div>
          ) : null}
        </div>
        <div>
          {" "}
          {optionsSelected !== "CHOISIR" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {!pdfGenerated && (
                    <button onClick={handleClick} className="button-generate">
                      Générer PDF
                    </button>
                  )}
                  {pdfGenerated && (
                    <PDFDownloadLink
                      style={{
                        marginTop: "15px",
                        alignSelf: "center",
                        width: "340px",
                      }}
                      className="button-generate"
                      document={<MyDoc />}
                      fileName="document.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Chargement..." : "Télécharger le PDF"
                      }
                    </PDFDownloadLink>
                  )}
                  {pdfGenerated && (
                    <div>
                      <PDFViewer width="100%" height={750} margin-bottom="15px">
                        <MyDoc />
                      </PDFViewer>
                    </div>
                  )}
                </div>
              </>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PDFGenerator;
