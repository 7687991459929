import { useQuery } from "@apollo/client";
import {
  GET_DOCUMENT_UNIQUE_INFOS,
  GET_FULL_DOCUMENT_UNIQUE,
} from "../../queries/documentUnique";
import { GET_USER } from "../../queries/user";
import { USER_ID_LS_KEY } from "../../queries/uniteTravail";
import { useEffect, useState } from "react";

import "../../assets/css/affichageDU.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import LoadingComponent from "../../components/Loading";
import { reformatDatas } from "../../utils/formatDatas";
import A4PagePaysage from "../../components/A4PagePaysage";
import A4PagePaysageLargeur from "../../components/A4PagePaysageLargeur";
import { format } from "date-fns";
import ErrorComponent from "../../components/ErrorComponent";
import {useMarqueBlanche} from "../../context/MarqueBlanche";

const DocumentUniqueConsultation = () => {
  const [logo, setLogo] = useState<any>("");
  const [raisonSociale, setRaisonSociale] = useState<any>("");
  const [dateEdition, setDateEdition] = useState<any>("");
  const handlePrint = () => {
    window.print(); // Lance l'impression
  };

  const { logoPath } = useMarqueBlanche();

  const { loading, error } = useQuery(GET_FULL_DOCUMENT_UNIQUE, {
    fetchPolicy: "no-cache",
    variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
    onCompleted(data) {
      setFormattedData(reformatDatas(data));
    },
  });

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER, {
    variables: {
      id: localStorage.getItem(
        "CognitoIdentityServiceProvider.7jkln9l9s8lsv95r5okpu9ue4e.LastAuthUser"
      ),
    },
  });

  const documentUniqueInfos = useQuery(GET_DOCUMENT_UNIQUE_INFOS, {
    variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
    onCompleted(data) {
      setFormattedData(reformatDatas(data));
    },
  });

  const { data: docData } = useQuery(GET_DOCUMENT_UNIQUE_INFOS, {
    variables: {
      id: localStorage.getItem(
        "CognitoIdentityServiceProvider.7jkln9l9s8lsv95r5okpu9ue4e.LastAuthUser"
      ),
    },
  });

  const [formattedData, setFormattedData] = useState<any[]>([]);

  useEffect(() => {
    if (userData) {
      if (userData?.utilisateur?.etablissements[0]) {
        console.log("ici", userData);
        setLogo(
          userData?.utilisateur?.etablissements[0]?.donnees?.infosAffichage
            ?.logo
        );
        setRaisonSociale(
          userData?.utilisateur?.etablissements[0]?.raisonsociale
        );
      }
    }
  }, [userData]);

  useEffect(() => {
    if (docData) {
      if (docData?.utilisateur?.etablissements[0]) {
        console.log("ici", docData);
        const dateEditionValue =
          docData?.utilisateur?.etablissements[0]?.donnees?.documentUnique
            ?.dateedition;
        if (dateEditionValue) {
          const formattedDate = format(
            new Date(dateEditionValue),
            "dd/MM/yyyy"
          );
          setDateEdition(formattedDate);
          console.log("formattedDate:", formattedDate);
        }
      }
    }
  }, [docData]);

  if (userLoading) return <LoadingComponent />;
  if (userError) return <ErrorComponent error={userError} />;

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error}/>;
  if (documentUniqueInfos.loading) return <LoadingComponent />;
  if (documentUniqueInfos.error) return <ErrorComponent error={documentUniqueInfos.error} />;

  console.log(formattedData);

  type Mesure = {
    ponderation: number;
    id: string;
  };

  type Description = {
    mesures: Mesure[];
    gravite: number;
    frequence: number;
    id: string;
  };

  function getTotalPonderations(description: Description): number {
    const totalPonderations = description.mesures.reduce(
      (sum: number, mesure: Mesure) => sum + mesure.ponderation,
      0
    );
    return totalPonderations;
  }

  function getMaitrise(description: Description): number {
    const P = getTotalPonderations(description);
    let M: number = 3;
    if (P >= 50 && P < 80) {
      M = 2;
    } else if (P >= 80) {
      M = 1;
    }
    return M;
  }

  function getCriticite(description: Description): number {
    const g = description.gravite;
    const f = description.frequence;
    const c: number = g * f * getMaitrise(description);

    return c;
  }

  function getPriorite(description: Description): number {
    const g = description.gravite;
    const f = description.frequence;
    const c: number = g * f * getMaitrise(description);
    const M = getMaitrise(description);
    let priorite: number = 0;

    if (M == null) {
      priorite = 1;
    }

    if (M !== 1) {
      if (c === 27) {
        priorite = 1;
      } else if (c >= 12 && c <= 27) {
        priorite = 2;
      } else if (c >= 5 && c < 12) {
        priorite = 3;
      } else if (c < 5) {
        priorite = 4;
      }
    } else {
      priorite = 4;
    }

    return priorite;
  }

  return (
    <>
      <div>
        <style>
          {`
    @media print {
      @page {
        size: A4 landscape; /* Format A4 paysage */
        margin: 0;
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        margin-left: 15px !important;
      }

      .print-page {
        page-break-inside: avoid;
      }
      #tableau {
        margin-top: 0 !important;
      }
    }
  `}
        </style>

        <A4PagePaysage>
          <button onClick={handlePrint} className="print-button DUbuttons">
            Imprimer
          </button>
          <div className="pageStyle">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" , gap:"160px"}}>
               {logo &&  <img src={logo} alt="Top Left Logo" className="logoStyle" />
              }  <img
                  src={logoPath}
                  style={{objectFit: "contain"}}
                  alt="Top Right Logo"
                  className="logoStyle"
                />
              </div>
            </div>
            <div>
              <h1 className="titleStyle">
                DOCUMENT UNIQUE D'EVALUATION DES RISQUES PROFESSIONNELS
              </h1>
              <h2 className="subtitleStyle">
                {raisonSociale
                  ? raisonSociale
                  : "Erreur lors du chargement de la raison sociale"}
              </h2>
              <p className="smallTextStyle">
                {dateEdition ? dateEdition : "Erreur date"}
              </p>
            </div>
            <p className="footerStyle">
              Tous droits réservés AT Consulting 2023
            </p>
          </div>
        </A4PagePaysage>
        <A4PagePaysage>
          <button onClick={handlePrint} className="print-button DUbuttons">
            Imprimer
          </button>
          <div className="pageStyle">
            <div>
              <h1 className="titleStyle">LEGENDE</h1>
              <h2 className="subtitleStyle" style={{ textAlign: "left" }}>
                FREQ : Indice de fréquence de survenance
              </h2>
              <h2 className="subtitleStyle" style={{ textAlign: "left" }}>
                GRAV : Indice de gravité
              </h2>
              <h2 className="subtitleStyle" style={{ textAlign: "left" }}>
                POND : Somme des pondérations des mesures de prévention
              </h2>
              <h2 className="subtitleStyle" style={{ textAlign: "left" }}>
                MAIT : Indice de niveau de maîtrise
              </h2>
              <h2 className="subtitleStyle" style={{ textAlign: "left" }}>
                CRIT : Indice de criticité
              </h2>
              <h2 className="subtitleStyle" style={{ textAlign: "left" }}>
                PRIO : Niveau de priorisation des actions à mener
              </h2>
            </div>
          </div>
        </A4PagePaysage>
        <div id="elementFixe"></div>
        <A4PagePaysageLargeur>
          <div id="tableau">
            <TableContainer style={{ overflow: "hidden" }}>
              <Table>
                <TableBody
                  style={{
                    margin: 0,
                    padding: 0,
                    display: "inline-block",
                  }}
                >
                  <TableRow
                    style={{
                      margin: 0,
                      padding: 0,
                      display: "inline-block",
                    }}
                  >
                    <TableCell className="celluleTitre unitehead">
                      UNITES TRAVAIL
                    </TableCell>
                    <TableCell className="celluleTitre risquehead">
                      RISQUES
                    </TableCell>
                    <TableCell className="celluleTitre descriptionhead">
                      SITUATIONS
                    </TableCell>
                    <TableCell className="celluleTitre frequencehead">
                      FREQ
                    </TableCell>
                    <TableCell className="celluleTitre frequencehead">
                      GRAV
                    </TableCell>
                    <TableCell className="celluleTitre mesurehead">
                      PREVENTION
                    </TableCell>
                    <TableCell className="celluleTitre specificitehead">
                      SPECIFICITES
                    </TableCell>
                    <TableCell className="celluleTitre frequencehead">
                      POND
                    </TableCell>
                    <TableCell className="celluleTitre frequencehead">
                      MAIT
                    </TableCell>
                    <TableCell className="celluleTitre frequencehead">
                      CRIT
                    </TableCell>
                    <TableCell
                      className="celluleTitre frequencehead"
                      style={{ paddingRight: "8px" }}
                    >
                      PRIO
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  {formattedData.map((ut: any) => (
                    <TableRow
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <div className="uniteencadrement">
                        <TableCell className="unitecontent">
                          {ut.libelle}
                        </TableCell>

                        <TableCell style={{ margin: 0, padding: 0 }}>
                          {ut?.risques?.map((risque: any, index: any) => (
                            <TableRow className="risqueencadrement">
                              <TableCell className="risquecontent">
                                {risque.libelle}
                              </TableCell>
                              <TableCell
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  display: "inline-block",
                                }}
                              >
                                {risque?.descriptions?.map(
                                  (description: any) => (
                                    <TableRow className="descriptionencadrement">
                                      <TableCell className="descriptioncontent">
                                        {description.description}
                                      </TableCell>
                                      <TableCell className="frequencecontent">
                                        {description.frequence}
                                      </TableCell>
                                      <TableCell className="frequencecontent">
                                        {description.gravite}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {description?.mesures.length > 0 ? (
                                          description.mesures.map(
                                            (mesure: any) => (
                                              <TableRow
                                                style={{
                                                  margin: 0,
                                                  padding: 0,
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                <TableCell className="mesurecontent">
                                                  {mesure.libelle}
                                                </TableCell>
                                                <TableCell className="specificitecontent">
                                                  {mesure.specificite || " "}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )
                                        ) : (
                                          <TableRow
                                            style={{
                                              flex: 1,
                                              height: "100%!important",
                                              display: "flex!important",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            <TableCell className="mesurecontent">
                                              {""}
                                            </TableCell>
                                            <TableCell className="specificitecontent"></TableCell>
                                          </TableRow>
                                        )}
                                      </TableCell>
                                      <TableCell className="frequencecontent">
                                        {getTotalPonderations(description)}
                                      </TableCell>
                                      <TableCell className="frequencecontent">
                                        {getMaitrise(description)}
                                      </TableCell>
                                      <TableCell className="frequencecontent">
                                        {getCriticite(description)}
                                      </TableCell>
                                      <TableCell
                                        className="frequencecontent"
                                        style={{
                                          backgroundColor:
                                            getPriorite(description) === 1
                                              ? "red"
                                              : getPriorite(description) === 2
                                              ? "orange"
                                              : getPriorite(description) === 3
                                              ? "yellow"
                                              : "green",
                                        }}
                                      >
                                        {getPriorite(description)}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableCell>
                      </div>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </A4PagePaysageLargeur>
      </div>
    </>
  );
};

export default DocumentUniqueConsultation;
