import { useState, useEffect } from "react";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
} from "amazon-cognito-identity-js";
import "../assets/css/login.css";
import "../assets/css/register.css";

// import modal mui
import { Modal, Fade, Button, TextField } from "@mui/material";

// import logo
import logo from "../assets/images/logoLogin.png";
import client from "../utils/http-common";
import {
  CREATE_ETABLISSEMENT,
  CREATE_USER,
  UPDATE_USER,
} from "../queries/user";
// yup verification
import * as yup from "yup";
import LoadingComponent from "../components/Loading";
import { ADD_AFFICHAGE } from "../queries/affichages";
import API_URL from "../utils/api";

const config = {
  region: "eu-central-1",
  UserPoolId: "eu-central-1_LKOvahkqY",
  ClientId: "7jkln9l9s8lsv95r5okpu9ue4e",
};

const userPool = new CognitoUserPool({
  UserPoolId: config.UserPoolId,
  ClientId: config.ClientId,
});

function Register() {
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setpasswordConfirmation] = useState("");
  const [metier, setMetier] = useState("");
  const [ape, setApe] = useState("");
  const [siret, setSiret] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [ville, setVille] = useState("");
  const [telephone, setTelephone] = useState("");
  const [nbSalaries, setNbSalaries] = useState(0);
  const [dirigeant, setDirigeant] = useState("");
  const [metiersList, setMetiersList] = useState([] as any);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [gcID, setGcID] = useState<string | null>("");

  useEffect(() => {
    if (metiersList.length !== 0) {
      return;
    }
    fetch(`${API_URL}/metiers`).then((response) => {
      response.json().then((data) => {
        setMetiersList(data.filter((metier: any) => !!metier.active));
      });
    });
  }, [metiersList]);

  useEffect(() => {
    // get url
    const url = window.location.href;
    // if "?idgc=xxx" is present in the URL, extract it
    if (url.includes("?idgc=")) {
      const idgc = url.split("?idgc=")[1];
      // do something with the idgc value
      console.log(idgc);
      setGcID(idgc);
    } else setGcID(null);
  }, []);

  // yup validation
  const schema = yup.object().shape({
    entreprise: yup.string().required("Champ(s) requis"),
    email: yup.string().email("Email invalide").required("Champ(s) requis"),
    password: yup
      .string()
      .min(8, "Le mot de passe doit contenir au moins 8 caractères")
      .required("Champ(s) requis"),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref("password"), undefined],
        "Les mots de passe doivent correspondre"
      )
      .required("Champ(s) requis"),
    metier: yup.string().required("Champ(s) requis"),
    ape: yup
      .string()
      .required("Champ(s) requis")
      .matches(/^\d{4}[A-Z]$/, "Code APE invalide"),
    siret: yup
      .string()
      .required("Champ(s) requis")
      .matches(/^\d{14}$/, "SIRET invalide"),
    adresse: yup.string().required("Champ(s) requis"),
    codePostal: yup
      .string()
      .required("Champ(s) requis")
      .min(5, "Code postal invalide"),
    ville: yup.string().required("Champ(s) requis"),
    telephone: yup.string().required("Champ(s) requis"),
    nbSalaries: yup
      .number()
      .required("Champ(s) requis")
      .positive()
      .min(1, "Le nombre de salarié doit être d'au moins 1"),
    dirigeant: yup.string().required("Champ(s) requis"),
  });

  const checkInputs = async (event: any) => {
    try {
      await schema.validate({
        entreprise: entreprise,
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation,
        metier: metier,
        ape: ape,
        siret: siret,
        adresse: adresse,
        codePostal: codePostal,
        ville: ville,
        telephone: telephone,
        nbSalaries: nbSalaries,
        dirigeant: dirigeant,
      });
      setError("");
      handleSignup(event);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const registerUserToDynamo = async (cognitoId: string) => {
    const metierId = metiersList.find((it: any) => it.libelle === metier)?.id;
    if (metierId === undefined) {
      console.log("metierId is undefined");
      return;
    }
    // set cognitoId to local storage
    localStorage.setItem("cognitoId", cognitoId);
    const result = await fetch(`${API_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: cognitoId,
        dirigeant: dirigeant,
        raisonsociale: entreprise,
        cp: codePostal,
        siret: siret,
        ville: ville,
        metierID: metierId,
        adresse: adresse,
        nbemployes: nbSalaries,
        telephone: telephone,
        ape: ape,
        userId: cognitoId,
        gcID: gcID,
      }),
    });

    const data = await result.json();

    // if no error, open modal
    if (!error && data.success) {
      setOpen(true);
    } else {
      setError("Erreur lors de l'inscription");
    }
  };

  const handleSignup = (event: any) => {
    if (error) {
      // break the function
      return;
    }
    event.preventDefault();

    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
    ];

    userPool.signUp(
      email,
      password,
      attributeList,
      attributeList,
      (error, result: any) => {
        if (error) {
          console.log(error);
          setError(error.message);
          return;
        } else {
          registerUserToDynamo(result.userSub).then(() => {});
        }
      }
    );
  };
  const confirmSignUp = (verificationCode: string) => {
    const username = localStorage.getItem("cognitoId");

    if (username) {
      const userData = {
        Username: username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmRegistration(
        verificationCode,
        true,
        (err: any, result: any) => {
          if (err) {
            // Gestion des erreurs
            console.log(err);
            setError(err.message);
          } else {
            // Inscription confirmée avec succès
            window.location.href = "/login";
          }
        }
      );
    } else {
      // Gestion du cas où 'username' est nul
    }
    if (gcID) {
      client.mutate({
        mutation: UPDATE_USER,
        variables: {
          id: username,
          gcID: gcID,
        },
      });
    }
  };

  useEffect(() => {
    if (error) {
      // if error contain "Password" & "long"
      if (error.includes("Password") && error.includes("long")) {
        setError("Le mot de passe doit contenir au moins 8 caractères");
      } else if (error.includes("email")) {
        setError("Email invalide ou déjà utilisé");
      } else if (error.includes("expression")) {
        setError("Erreur lors de l'inscription");
      } else if (error.includes("uppercase")) {
        setError("Le mot de passe doit contenir au moins une majuscule");
      } else if (error.includes("lowercase")) {
        setError("Le mot de passe doit contenir au moins une minuscule");
      } else if (error.includes("numeric")) {
        setError("Le mot de passe doit contenir au moins un chiffre");
      }
    }
  }, [error]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="register">
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="modal"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              outline: "none",
              textAlign: "center",
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2 id="transition-modal-title">Inscription réussie !</h2>
            <p id="transition-modal-description">
              Votre inscription a bien été prise en compte. Vous allez recevoir
              un email de confirmation avec un code de vérification.
            </p>
            <TextField
              id="outlined-basic"
              label="Code de vérification"
              variant="outlined"
              style={{ width: "30%", marginBottom: "20px" }}
              value={code}
              onChange={(event) => {
                setCode(event.target.value);
              }}
            />
            <Button
              variant="contained"
              onClick={() => {
                setLoading(true);
                confirmSignUp(code);
              }}
              className="modalButton"
            >
              Valider
            </Button>
          </div>
        </Fade>
      </Modal>
      <div className="registerForm">
        <div className="headForm">
          <img src={logo} alt="logo" />
          <h5>Création de compte client</h5>
          <p>Renseignez les éléments ci-dessous</p>
          <div className="line"></div>
          {error && (
            <div className="error" style={{ textAlign: "center" }}>
              {error}
            </div>
          )}
        </div>
        <div className="registerFormSections">
          <div className="registerFormSection">
            <p>Nom de l'entreprise : *</p>
            <input
              type="text"
              placeholder="Entreprise"
              value={entreprise}
              onChange={(event) => setEntreprise(event.target.value)}
            />
            <p>Adresse email : *</p>
            <input
              type="email"
              placeholder="Adresse email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <p style={{ marginBottom: "0px" }}>Mot de passe : *</p>
            <p
              style={{
                fontSize: "12px",
                color: "grey",
                marginTop: "0px",
                width: "80%",
              }}
            >
              (Le mot de passe doit contenir au moins 8 caractères, une
              majuscule, une minuscule et un chiffre.)
            </p>
            <input
              type="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <p>Confirmation du mot de passe : *</p>
            <input
              type="password"
              placeholder="Mot de passe de confirmation"
              value={passwordConfirmation}
              onChange={(event) => setpasswordConfirmation(event.target.value)}
            />

            <p>Nombre de salariés : *</p>
            <input
              type="number"
              placeholder="Nombre de salariés"
              value={nbSalaries}
              onChange={(event) => setNbSalaries(parseInt(event.target.value))}
            />
            <p>Dirigeant : *</p>
            <input
              type="text"
              placeholder="Dirigeant"
              value={dirigeant}
              onChange={(event) => setDirigeant(event.target.value)}
            />
            {gcID && (
              <>
                <p>Rattachement Grand Compte : </p>
                <input
                  type="text"
                  style={{ color: "darkgray" }}
                  placeholder="Grand Compte"
                  value={gcID}
                  disabled
                />
              </>
            )}
          </div>
          <div className="registerFormSection">
            <p>Métier : *</p>
            <select
              name="metier"
              id="metier"
              value={metier}
              onChange={(event) => setMetier(event.target.value)}
              style={{
                width: "80%",
                height: "50px",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "#EAEAEA",
              }}
            >
              <option value="">Choisir un métier</option>
              {metiersList.map((metier: any) => (
                <option key={metier.id} value={metier.libelle}>
                  {metier.libelle}
                </option>
              ))}
            </select>
            <p>Code APE : *</p>
            <input
              type="text"
              placeholder="Code APE"
              value={ape}
              onChange={(event) => setApe(event.target.value)}
            />
            <p>SIRET : *</p>
            <input
              type="text"
              placeholder="SIRET"
              value={siret}
              onChange={(event) => setSiret(event.target.value)}
            />
            <p>Adresse : *</p>
            <input
              type="text"
              placeholder="Adresse"
              value={adresse}
              onChange={(event) => setAdresse(event.target.value)}
            />
            <p>Code postal : *</p>
            <input
              type="text"
              placeholder="Code postal"
              value={codePostal}
              onChange={(event) => setCodePostal(event.target.value)}
            />
            <p>Ville : *</p>
            <input
              type="text"
              placeholder="Ville"
              value={ville}
              onChange={(event) => setVille(event.target.value)}
            />
            <p>Téléphone : *</p>
            <input
              type="text"
              placeholder="Téléphone"
              value={telephone}
              onChange={(event) => setTelephone(event.target.value)}
            />
          </div>
        </div>
        <div className="footerForm">
          <button onClick={checkInputs}>INSCRIPTION</button>
          <div className="line"></div>
          <p>
            Déjà utilisateur ? <a href="./login">Connexion</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
